/* eslint-env browser */

let timer = null;
export function resetTimer() {
  timer = Date.now();
}

export function elapsedTime() {
  if (!timer) {
    return null;
  }

  const timeElapsed = Date.now() - timer;
  timer = null;

  return timeElapsed;
}

let navigationEnd = null;
export function navigationDuration() {
  if (!navigationEnd) {
    navigationEnd = Date.now();
    return navigationEnd - window.performance.timing.navigationStart;
  }

  return null;
}
