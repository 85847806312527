import { localWithFallback } from '@wise/wise-storage';

let keyPrefix: string | null = null;
export function init(clientId: string) {
  if (!clientId) {
    throw new Error('No clientid given to local storage initialization');
  }

  keyPrefix = `w-${clientId}-v1/`;
}

export function set(key: string, value: any) {
  if (keyPrefix === null) {
    throw new Error('No clientid given to local storage');
  }

  const generatedKey = `${keyPrefix}${key}`;
  localWithFallback.set(generatedKey, value);
}

export function get<T = any>(key: string, defaultValue?: T): T | undefined {
  if (keyPrefix === null) {
    throw new Error('No clientid given to local storage');
  }

  const generatedKey = `${keyPrefix}${key}`;
  return localWithFallback.get(generatedKey, defaultValue) as T;
}
