import { Route } from '../routes.types';

export const germanPageTitles: Record<Route, string> = {
  welcome: 'Willkommen',
  'dwsjapan.wise': 'DWS Japan Weitsicht',
  'dwsjapan.wise/faq': 'DWS Japan Weitsicht FAQ',
  'how-it-works': 'Wie es funktioniert',
  faq: 'Häufige Fragen',
  'investment-goal': 'Anlageziel',
  'personal-profile': 'Persönliches Profil',
  'investment-amount': 'Anlagebetrag',
  'investment-horizon': 'Anlagehorizont',
  risk: 'Risiko',
  'investment-plan': 'Anlageplan',
  knowledge: 'Wissen',
  'financial-services': 'Finanzdienstleistungen',
  experience: 'Erfahrung',
  'nearly-there': 'Fast da',
  'financial-status': 'Finanzstatus',
  'financial-status-income': 'Finanzstatus Einkommen',
  'financial-status-disposable': 'Finanzstatus Verfügbares Einkommen',
  'financial-status-assets': 'Finanzstatus Vermögen',
  'financial-status-liabilities': 'Finanzstatus Verbindlichkeiten',
  sustainability: 'Nachhaltigkeit',
  'sustainability-landing': 'Nachhaltigkeit Landing',
  'sustainability-pick': 'Nachhaltigkeit Auswahl',
  'sustainability-criteria': 'Nachhaltigkeitskriterien',
  summary: 'Zusammenfassung',
  handover: 'Übergabe',
};
