/* eslint-env browser */
import get from 'lodash/get';
import trim from 'lodash/trim';
import debounce from 'lodash/debounce';

import { local } from '@wise/wise-storage';

import { log, warn, table } from './console';
import { getConfiguration } from './configuration';
import i18n from '../i18n';

import { detectDeviceType } from './device';
import { getOptOutState } from './optOut';
import { postMessage } from './iframeCommunication';

import { elapsedTime, navigationDuration } from './performanceMeasurement';

let currentStore = null;
const attachments = []; // Attachments to next tracking event

function normalizeDeviceType(deviceTypeName) {
  if (!deviceTypeName) {
    return 'desktop journey'; // Fall back
  }
  return `${deviceTypeName} journey`;
}
const deviceType = normalizeDeviceType(detectDeviceType(navigator.userAgent));

function getSubjectIdFromToken(idToken, defaultValue = null) {
  try {
    return JSON.parse(atob(idToken.split('.')[1])).sub;
  } catch (error) {
    return defaultValue;
  }
}

async function getStateInfo() {
  if (!currentStore) {
    return {};
  }

  const state = currentStore.getState();

  let idToken = get(state, 'local.auth.idToken');
  if (!idToken) {
    const idTokenPromise = get(state, 'local.idTokenPromise');
    idToken = await idTokenPromise;
  }

  return {
    vinfo: get(state, 'profile.vinfo', ''),
    session_id: getSubjectIdFromToken(idToken, ''),
  };
}

const UTAG_DATA = {
  datalayer_version: '1.0.0',
  site_responsivetype: deviceType,
  site_family: 'dam',
  site_country: 'lu',
  site_business: 'wise',
  user_logged_status: 'logged out',
  page_type: 'aab',
};

let currentSource = null;

async function generateDynamicUTAGData(page) {
  let environmentName = 'dev';
  if (getConfiguration('isProduction')) {
    environmentName = 'prod';
  } else if (getConfiguration('isUAT')) {
    environmentName = 'stage';
  }

  const stateInfo = await getStateInfo();
  return {
    ...stateInfo,
    site_language: i18n.language,
    site_envwork: environmentName,
    partner_id: getConfiguration('clientId'),
    partner_name: getConfiguration('tenantName'),
    query_string: getConfiguration('queryString'),
    site_referrer: getConfiguration('referrer', ''),
    page_version: getConfiguration('version'),
    page_name: `dam|wise|lu|${i18n.language}|/${page}`,
  };
}

// eslint-disable-next-line complexity
export async function utagLink(
  source,
  target,
  title,
  linktype,
  action,
  id,
  optionalData = {}
) {
  postMessage('wise-tracking', {
    trackingType: 'link',
    source,
    target,
    title,
    linktype,
    action,
    id,
    optionalData,
  });
  if (getOptOutState()) {
    return;
  }
  currentSource = source;
  try {
    const language = i18n.language;

    const additionalValues = {};
    if (action) {
      additionalValues.event_action = action;
    }
    if (id) {
      additionalValues.event_id = id;
    }

    const dynamicData = await generateDynamicUTAGData(source);

    const utagData = {
      ...UTAG_DATA,
      ...dynamicData,
      ...additionalValues,
      event_title: trim(title),
      event_linktype: linktype,
      event_source: `dam|wise|lu|${language}|/${source}`,
      event_target: `dam|wise|lu|${language}|/${target}`,
      ...optionalData,
    };

    if (local.get('v3/utag-debug')) {
      // eslint-disable-next-line no-console
      console.log(utagData);
    }

    if (window.utag && window.utag.link) {
      window.utag.link(utagData);
    }
  } catch (error) {
    console.log('Error in utag', error); // eslint-disable-line no-console
  }
}

let lastPage = null;
// eslint-disable-next-line complexity
export async function utagView(page, disableCurrentSource) {
  postMessage('wise-tracking', { trackingType: 'view', page });
  if (getOptOutState()) {
    return;
  }
  if (!disableCurrentSource) {
    currentSource = page;
  }

  if (page === lastPage) {
    warn(
      `Multiple UTAG view requests for same page "${page}". Please set "waitForRequests" on page to true.`
    );
    return;
  }
  lastPage = page;

  try {
    if (window.utag) {
      const dynamicData = await generateDynamicUTAGData(page);

      const timingNavigation = elapsedTime();
      const timingBrowserLoadNavigation = navigationDuration();

      const timingValues = {};
      if (timingNavigation) {
        timingValues.timing_navigation = String(timingNavigation);
      }
      if (timingBrowserLoadNavigation) {
        timingValues.timing_load = String(timingBrowserLoadNavigation);
      }

      let attachmentObj = {};
      if (attachments.length > 0) {
        const state = currentStore.getState();
        attachmentObj = attachments.reduce(
          (prev, { selector, name, accessor }) => ({
            ...prev,
            [name]: accessor(state, selector),
          }),
          {}
        );

        attachments.length = 0;
      }

      const utagData = {
        ...UTAG_DATA,
        ...timingValues,
        ...dynamicData,
        ...attachmentObj,
      };

      if (local.get('v3/utag-debug')) {
        // eslint-disable-next-line no-console
        table(utagData);
      }

      if (window.utag && window.utag.view) {
        window.utag.view(utagData);
      }
    }
  } catch (error) {
    log('Error in utag', error);
  }
}

export function utagAction(type, action, title, id) {
  postMessage('wise-tracking', {
    trackingType: 'action',
    type,
    action,
    title,
    id,
  });
  return utagLink(currentSource, currentSource, title, type, action, id);
}

export function utagTrackData(selector, name, accessor = get) {
  postMessage('wise-tracking', {
    trackingType: 'data',
    selector,
    name,
    accessor,
  });
  if (getOptOutState()) {
    return;
  }
  attachments.push({ selector, name, accessor });
}

export function utagTrackError(eventId, eventError, eventErrorMessage) {
  postMessage('wise-tracking', {
    trackingType: 'error',
    eventId,
    eventError,
    eventErrorMessage,
  });
  return utagLink(
    currentSource,
    currentSource,
    'errormessage',
    'error',
    null,
    eventId,
    {
      event_error: eventError,
      event_error_message: eventErrorMessage,
    }
  );
}

const debounceCache = new Map();
const debounceArgsCache = new Map();
function debounceWithParameter2(fnt, wait) {
  return (id, ...args) => {
    debounceArgsCache.set(id, args);

    if (!debounceCache.has(id)) {
      const dbncRunner = () => {
        const runnerArgs = debounceArgsCache.get(id);
        debounceCache.delete(id);
        debounceArgsCache.delete(id);
        return fnt(...runnerArgs);
      };

      debounceCache.set(
        id,
        debounce(dbncRunner, wait, {
          trailing: true,
          leading: false,
        })
      );
    }

    // Run debouncer fnt
    debounceCache.get(id)();

    return () => {
      debounceCache.get(id).cancel();
      debounceCache.delete(id);
      debounceArgsCache.delete(id);
    };
  };
}
export function cancelDebounce(id) {
  if (debounceCache.has(id)) {
    debounceCache.get(id).cancel();
    debounceCache.delete(id);
    debounceArgsCache.delete(id);
  }
}

export const utagTrackErrorDebounced = debounceWithParameter2(
  utagTrackError,
  1000
);

export function injectStore(store) {
  currentStore = store;
}
