/**
 * Reference to available static routes
 */
export const staticRoutes = new Set([
  { clientId: 'MIRAI', path: '/dwsjapan.wise' },
]);

/**
 * Searches the static routes set for a matching base path given a url hash
 *
 * @param {string} urlHash The window.location.hash value
 * @returns {object} Object containing the matched value, otherwise False
 */
export default function containsStaticRoute(urlHash = '') {
  let result = false;
  for (const route of staticRoutes) {
    result = urlHash.startsWith(`#${route.path}`) ? route : result;
  }
  return result;
}
