/* eslint-disable quote-props */
/* eslint-env browser */
import { PureComponent } from 'react';
import noop from 'lodash/noop';
import { parseUrl } from '@wise/shared/src/string-helper';
import { localWithFallback as local } from '@wise/wise-storage';
import { setNumberLocale, setDateTimeLocale } from '@wise/wise-localization';
import { parse } from 'cookie';

import {
  getSessionItem,
  clearSession as clearSessionStore,
  init as initSessionStorage,
} from './services/session';
import { init as initLocalStorage } from './services/localStorage';

import { log, error as logError } from './services/console';

import sha1 from './services/sha1';

import {
  setConfiguration,
  getConfiguration,
  setConfigurationValue,
} from './services/configuration';

import detectLanguage from './services/language';

import { getOptOutState } from './services/optOut';
import containsStaticRoute from './services/staticRoutes';
import { inIframe } from './services/iframeCommunication';
import { setNavigationFlow } from './services/navigation';
import { cleanAndReset } from './services/clearSession';

import githashContent from './githash.json';
import packageContent from '../../package.json';
import NoMatchComponent from './pages/NoMatch';
import { ETracker } from '../eTracker/eTracker';
import { Cookiebot } from './components/Cookiebot/Cookiebot';

const { githash } = githashContent;
const { version } = packageContent;

// This is a SHA1 map of clientid -> tenant configuration
// Please make sure to also update json files in wise-integration
// if new clients are added
const clientMap = {
  // LOIP + DWS special case 'LOIP' as sha1(), clientid is detected in tenant specific code
  '0cba24c396ae78a9235d5efc1562399b312f5c27': () => import('./tenants/DWS'),

  // DWS direkt internal 8xz3ajx5NxQETSlgUx5gwzYxjUTN
  '3c996d5597c37e0b7fff845a8a0ea80b4701ec43': () => import('./tenants/DWS'),

  // DWS direkt production TxjxgU5NaNlYTxjxUgEz5x3wSz8Q
  '706c799296549e33eb1099fe236bb2e1c9c22403': () => import('./tenants/DWS'),

  // Godmode internal jxQgwESTjg8xxaxYUNTzNx3U5lz5
  '6f85f68eba6e5ee2a24a48d72b90073fa01280cd': () => import('./tenants/DWS'),

  // Godmode production xaSQxNUxUw5xj3jl8N5xTzgTYEzg
  f7b0f9fffc2017c77b3986c26766783d0cdd1783: () => import('./tenants/DWS'),

  // MorgenFund internal BtwqV20VJJhOmw1CrBvppuk5K0gA
  '107290806a8246f434a5a74fc156c991dccb9b38': () =>
    import('./tenants/Morgenfund'),

  // MorgenFund production p2cksnDarWr4HaLWGah0bI7Gqptg
  '81c929c0fe92a87259008fd2745a90b726058b50': () =>
    import('./tenants/Morgenfund'),

  // Apella internal joEnWBN5RMGkxtya2ObP8Zfqa52A
  '238a5040a8561c590220301b7c8063e058ab7309': () => import('./tenants/Apella'),

  // Apella production OyGaxo6xB2MFZepgUKMGDLMZr93A
  '9c0353448a80410a477d7bb21cd1ae646e0ade2d': () => import('./tenants/Apella'),

  // BancoBest internal q3Py3t8eDcwLFcNiy5M2cAnBUgjQ
  cd2fc2f9e4f8dc2db1543bbdb4377cb1cfaee726: () => import('./tenants/BancoBest'),

  // BancoBest production TT49iQYgWizVMHLl1q9KBVatWJwQ
  '9dfa8e8ae2688cd49ba3f16e68c68fa6ce82ede8': () =>
    import('./tenants/BancoBest'),

  // Basler internal xjQyxjE5xTc0xzQ2NjIyODA0xjga
  '265596a4cf97c23df96af939d5c0a294a2819ee0': () => import('./tenants/Basler'),

  // Basler production NzcxOTE4Nzc4xTk4xDQ4xjY1xjUa
  bdf2abb0be206b9c36ea2b5ba380fdf15a941264: () => import('./tenants/Basler'),

  // Basler Pro (T2) internal VLanbF3QoulIaRuAhcEEawYmC05w
  a6d997c5333fd8fcc153101a456ada9050496073: () => import('./tenants/BaslerPro'),

  // Basler Pro (T2) production 2B8wn7HG83DNbFUbPXNjtL69AZJA
  a364a16ef7ff37a4fbcea427d0cdc5e2b0e9538f: () => import('./tenants/BaslerPro'),

  // Basler Premium (T3) internal 2C6mSMU6PEBamlyQllInIkkZsyoA
  c3be61324547e608461bb3aca395611b0f50e30b: () =>
    import('./tenants/BaslerPremium'),

  // Basler Premium (T3) production lVDEGTeblbXNdBBoFzU4VHShDBlA
  '5f42970777d5e9fe8442c4b2903baded92c17df8': () =>
    import('./tenants/BaslerPremium'),

  // BFV internal 5lTxaxj8NYz3jQ5ggxTxxUzSENwU
  '9ff4c9c474012370bbdbca07447ab3095efcebb2': () => import('./tenants/BfV'),

  // BFV production Eg85zzjxSYUTlQxU5TNx3wxNxjag
  '5113ba7f5672f070fc8a702b8b4fca1e0f80d60b': () => import('./tenants/BfV'),

  // Buddenbrock internal mvkC3mNrsL51z22BLzG5Sn8ORWfw
  '9be30616f616c60b69a560858f71a32f0e0c1ef1': () =>
    import('./tenants/Buddenbrock'),

  // Buddenbrock production g3MRSuRKqX88O4nrMTLszAXjBarg
  c9ad96c858b3640366481213becaa5ef8467c3ed: () =>
    import('./tenants/Buddenbrock'),

  // ezfunds internal NTI0NTUxODExODA5xzE4NTc0xTUa
  e8d7df1d39632f79e94193875a17bd9433afafb2: () => import('./tenants/ezfunds'),

  // ezfunds production EwESYTx4xANdNaYTz5zUyTUzQx8x
  '3a93bb25e0e01b87f3239a01ca228da2c12d1c21': () => import('./tenants/ezfunds'),

  // FondsDiscount internal x3NQzxgxjl8YjUgTSTaxNU5Ewz5x
  '6d09ec11911eaa5b19f8ec41439d789206386f36': () =>
    import('./tenants/FondsDiscount'),

  // FondsDiscount production S3TNwgYxjjx5xxTxzQElgN5UaU8z
  c7e117e3eb4ade050812cdb70782c770214235d5: () =>
    import('./tenants/FondsDiscount'),

  // FondsFinanz internal wFuMUGx20ah94GkyiUQjsTIYDdfQ
  ee93e0af6a4f9b8c8d51f4b65c1a0e3506fc9dd7: () =>
    import('./tenants/FondsFinanz'),

  // FondsFinanz production WA1DmZzXFWGBV1AHgletS4RvSQrA
  '7febebb537fdec826de0d35681beb12636c53ab9': () =>
    import('./tenants/FondsFinanz'),

  // FinanzGuru internal 9ttzmSV5IcNDlahQYZm1CeB9Cs4Q
  c58a2ce0bda174d4e9f56e9b26d44eaaefcbf948: () =>
    import('./tenants/Finanzguru'),

  // FinanzGuru production mOns8oP0yPXODKXevmGD6Nf5m4YA
  '42658c4293c1af802683093ae39d91aabfd22790': () =>
    import('./tenants/Finanzguru'),

  // InvestFinder internal RdSr0IQqGSwt503VYzPe1j8JG2sQ
  ad3dc47b6898cd9517f88e59db48d35c28615d1e: () =>
    import('./tenants/InvestFinder'),

  // InvestFinder production CEqvRE4I6vPGzbr6JYGpfBAGVPfA
  ecc46796cd88d90c6fbbe5161ad05050ec4fd0e7: () =>
    import('./tenants/InvestFinder'),

  // JDC internal k1WIsEXBx8dpfJFGGeFiHIFVzSow
  '4e3fad7f5517136412fd7872114ef99ffff9e67a': () => import('./tenants/JDC'),

  // JDC production 0SAYNetfBiBQMX4F1Y1BXoajnLRw
  '0c86b9bfa0c6d204aa627305a3e10c0774aa49c7': () => import('./tenants/JDC'),

  // NetFonds internal reWqRZ0wZQYn0GhLTpnc44yPYyUw
  af413bcb99ffc63400d8181a37f83f138dc5acfa: () => import('./tenants/NetFonds'),

  // NetFonds production 6YDC7UcLvLaP1gQ79QqOOepXv1Jw
  cf9922c66c599099721cdf450c729e6f50d2092f: () => import('./tenants/NetFonds'),

  // ETFRobo internal guJIeLtscGDNjLkO8kwWeZh9m6Xg
  e8f870c1eedbb5ed82501587fd483c531e37e079: () => import('./tenants/ETFRobo'),

  // ETFRobo production w8YLzJCaAKdO9CqUoOPVKNmslYFw
  '82a697a1c10c5f5392770e3a73ec605b8eef299e': () => import('./tenants/ETFRobo'),

  // Trigonus internal SmT935b0SUDphbGqWJqeArW9YPvw
  dfb882d0fba8d992bd36918b60320d70518b1fba: () => import('./tenants/Trigonus'),

  // Trigonus production 5WUVYU47nuszGfFyZz04UtxrA4Dg
  '6d80cb7b0b257d836001b78b4c1459154a579d1a': () =>
    import('./tenants/Trigonus'),

  // VDW internal 9df7bxa6GcKDRFjsVz9qdaLpoJBC
  c053a36511672b937ea40dca8758e104034f7a10: () => import('./tenants/VDW'),

  // VDW production NUST5zTjENEyN8cTN1aQwNYUxzxI
  '821ecc2b035588a222c3e9dc1475ece9de2fa777': () => import('./tenants/VDW'),

  // Volkswohl internal xQJu2tCZWeilqJg5kY5ThG4T2yBA
  '1e7cbc7605bfeee489886f0d8bb98e99927fbfab': () =>
    import('./tenants/Volkswohl'),

  // Volkswohl production AvNf29WJ71UA5Y0nXPrL2HrfGBSw
  '2c1007341cdfc97f965372804640c2bef973c1af': () =>
    import('./tenants/Volkswohl'),

  // Mirai internal EDDdBTKbn66KlrtlhaGULXKvDInA
  '4e7eb5ad66fe7e37e3dcba2949313dd88bd7a4c6': () => import('./tenants/Mirai'),

  // Mirai production GbHsMqOA8TsgMyllszQoUOZLSAIw
  '5b5d61fbfe454edda4743128cb6678c0d90dfa91': () => import('./tenants/Mirai'),

  // Mirai Marketing special case 'MIRAI' as sha1(), clientid is detected in tenant specific code
  f5650f6b366972118c1fa13477c9fdcee11b54d8: () =>
    import('./tenants/MiraiMarketing'),

  // easyInvesto ESG internal mrqYbupas0gFbVZWHl19KNTFIvxQ
  d1a035dd493ba31209ee5f5b329d97eb2e92748f: () =>
    import('./tenants/FondsFinanzESG'),

  // easyInvesto ESG production 2l1XxfAFP5XcJiAPrx6RXLifsb9g
  a982c72f549a0ac46ab88c7822350d0631d86e13: () =>
    import('./tenants/FondsFinanzESG'),

  // BFV ETF ESG internal 5NDeq0UfEY2W7CL6COaYK9cC6bGw
  f22f2f1a4f5cb2637ac4f12e977625fb34aeac89: () => import('./tenants/BfVESG'),

  // BFV ETF ESG production j46rRwO3RhYKVTLJ4UOzrxon0ysg
  c3a0ef0a596f520d1682c4232dc20cb26f05ca12: () => import('./tenants/BfVESG'),
};

const { body } = window.document;
body.classList.add('wise');
body.parentElement.setAttribute('data-wise-version', version);
body.parentElement.setAttribute('data-wise-hash', githash);

window.document.domain = window.location.hostname;

// eslint-disable-next-line complexity
function hasDebugURL() {
  try {
    if (window.location.href.indexOf('testSetup') > 0) {
      return false;
    }

    const wiseDebugURL = local.getItem('WISE_DEBUG_URL');
    if (wiseDebugURL) {
      log('Running in test mode!');
      const newUrl = JSON.parse(wiseDebugURL);

      const loc = window.location;

      if (loc.search.includes('redirect=')) {
        const redirectParam = loc.search
          .substring(1)
          .split('&')
          .filter((item) => item.includes('redirect='))[0];

        const definedSearchString =
          newUrl.search.length > 1 ? newUrl.search.substring(1).split('&') : [];

        newUrl.search = `?${definedSearchString
          .concat([redirectParam])
          .join('&')}`;
      } else if (loc.search.includes('backurl=')) {
        const redirectParam = loc.search
          .substring(1)
          .split('&')
          .filter((item) => item.includes('backurl='))[0];

        const definedSearchString =
          newUrl.search.length > 1 ? newUrl.search.substring(1).split('&') : [];

        newUrl.search = `?${definedSearchString
          .concat([redirectParam])
          .join('&')}`;
      }

      const currentLoc = `${window.location.protocol}//${window.location.host}`;

      if (currentLoc.indexOf(newUrl.url) < 0) {
        log('Change to test environment ', newUrl.url);
        window.location.href = `${newUrl.url}${newUrl.search}${loc.hash}`;

        return true;
      }
    }
  } catch (error) {
    console.error(error.stack); // eslint-disable-line no-console
  }

  return false;
}

function polyfillFetch() {
  if (window.fetch) {
    return true;
  }

  log('Polyfill fetch()');
  return import('whatwg-fetch');
}

function bootLoader() {
  const bootLoaderTasks = [polyfillFetch()];

  return Promise.all(bootLoaderTasks);
}

async function findClientId() {
  const clientIdFinder = await import('./tenants/clientIdFinder');

  return clientIdFinder.default();
}

async function tenantLoader() {
  const query = parseUrl();

  // Detected client ID without fallback, or undefined if no detection
  const clientId = (query && query.clientid) || (await findClientId());

  const clientHash = sha1(clientId);

  let tenantDataLoader = clientMap[clientHash];
  if (!tenantDataLoader) {
    // Always fall back to LOIP tenant
    tenantDataLoader = clientMap[sha1('y4Hzrbxq0Dlchr7g5MlkIYM05nka')];
  }
  const loader = await tenantDataLoader();

  // Load tenant with detected or undefined client id
  const data = loader.default(clientId);

  return data;
}

async function staticRouteTenantLoader(clientId) {
  const clientHash = sha1(clientId);

  const tenantDataLoader = clientMap[clientHash];
  const loader = await tenantDataLoader();

  return loader.default();
}

function loadUtagLib() {
  const doc = window.document;

  window.utag_cfg_ovrd = {
    noview: true,
  };

  let url;
  if (getConfiguration('isProduction')) {
    url = 'https://www.deutscheinvest.lu/static-assets/utag-prod/utag.js';
  } else {
    url = '/static-assets/utag-dev/utag.js';
  }

  window.WISE_LOAD_UTAG = noop;

  const realLoadUtagLib = () => {
    window.WISE_LOAD_UTAG = noop;

    const utag = doc.createElement('script');
    utag.src = `${url}?v=${githash}`;
    utag.type = 'text/javascript';
    utag.async = true;
    doc.head.appendChild(utag);
  };

  if (getOptOutState()) {
    window.WISE_LOAD_UTAG = realLoadUtagLib;
  } else {
    realLoadUtagLib();
  }
}

function isIdentityServerCallback() {
  const { hash } = window.location;
  return (
    hash.indexOf('code=') > 0 ||
    hash.indexOf('id_token=') > 0 ||
    hash.indexOf('error=') > 0
  );
}

function handleIdentityServerCallback() {
  log('Identity Server callback');

  const { hash } = window.location;
  if (hash.indexOf('error=') > 0) {
    logError('Generic authentication provider error!', hash);
    return;
  }

  const safeOrigin = `${window.location.protocol}//${window.location.host}`;
  let w = window;
  try {
    while (w.parent !== w) {
      w = w.parent;

      // Next line is only to check if w.origin is accessible. Otherwise an error is thrown.
      // eslint-disable-next-line no-unused-vars
      const { origin } = w;

      w.postMessage(
        {
          type: 'wise-auth',
          content: hash,
        },
        safeOrigin
      );
    }
  } catch (error) {
    // Noop - show no error because parent is other origin
  }
}

function checkSessionStoragePage() {
  const { history, location, self, top } = window;
  if (self === top) {
    // We are not inside iframe
    return;
  }

  try {
    const path = getSessionItem('v3/path');
    if (path && location.hash.length > 2) {
      history.pushState(null, null, `${location.href.split('#')[0]}#${path}`);
    }
  } catch (error) {
    // do nothing
  }
}

// Check if clean session parameter is set. If so, clear session.
function clearSession() {
  const query = parseUrl();

  if (query && query.session === 'clean') {
    log('Clear old session...');
    try {
      clearSessionStore();
    } catch (error) {
      // noop
    }
    try {
      localStorage.clear();
    } catch (error) {
      // noop
    }
    try {
      const newLocation = location.href.replace(
        'session=clean',
        'session=stay'
      );
      history.pushState(null, document.title, newLocation);
    } catch (error) {
      // noop
    }
  }
}

function updateLandingPageData() {
  const storedLandingpageData =
    local.get('v3/landingpage') || getSessionItem('v3/landingpage');

  if (!inIframe()) {
    setConfigurationValue('referrer', window.document.referrer);
  }

  if (storedLandingpageData) {
    if (storedLandingpageData.referrer) {
      setConfigurationValue('referrer', storedLandingpageData.referrer);
    }
  }
}

export function setDocumentLanguage(languageValue) {
  document.querySelector('html').setAttribute('lang', languageValue);
  return this;
}

function checkStaticRoutes() {
  const { hash } = window.location;
  return containsStaticRoute(hash);
}

class Boot extends PureComponent {
  // eslint-disable-next-line complexity
  async componentDidMount() {
    const idServerCallback = isIdentityServerCallback();
    if (idServerCallback) {
      handleIdentityServerCallback();
      return;
    }

    if (hasDebugURL()) {
      return;
    }

    log(`WISE build ${version}-${githash}`);

    const currentCookieState = window.document.cookie;
    const cookies = parse(currentCookieState);

    if (
      cookies.WISE_OPT_OUT === undefined ||
      cookies.WISE_TRACKING_OPT_OUT === undefined
    ) {
      window.document.cookie = 'WISE_OPT_OUT = 1';
      window.document.cookie = 'WISE_TRACKING_OPT_OUT = 1';
    }

    log('Load dynamic imports...');
    await bootLoader();

    const isStaticRoute = checkStaticRoutes();

    log('Load tenant specific imports...');
    const tenantConfig = isStaticRoute
      ? await staticRouteTenantLoader(isStaticRoute.clientId)
      : await tenantLoader();

    if (!tenantConfig.tenantName) {
      // Tenant unknown, so show error message
      this.setState({ App: NoMatchComponent });
      return;
    }

    let theme = tenantConfig.tenantName.toLowerCase();

    if (theme === 'loip' || theme === 'godmode' || theme === 'dws') {
      theme = 'morgenfund';
    }

    setConfiguration(tenantConfig);
    setConfigurationValue('version', `${version}-${githash}`);

    setConfigurationValue('current.isStaticRoute', isStaticRoute);

    log('Initialize session');
    initSessionStorage(tenantConfig.clientId);
    initLocalStorage(tenantConfig.clientId);

    // Check if clean session parameter is set. If so, clear session.
    clearSession();

    log('Check for page in storage...');
    await checkSessionStoragePage();

    updateLandingPageData();

    log('Loaded configuration for', tenantConfig.tenantName);

    const defaultLanguage = getConfiguration('language.default');
    const languageMap = Object.keys(getConfiguration('language.loaders'));
    const locale = await detectLanguage(defaultLanguage, languageMap);
    log(`Done. Detected language is "${locale}" so load it...`);
    const languageLoader = getConfiguration(`language.loaders[${locale}]`);
    const hasOwnNavigationFlow = getConfiguration('navigationFlow');

    // To make sure that we get the flow taking into account configuration
    setNavigationFlow(hasOwnNavigationFlow);

    // Set de as number locale if locale is pt to get german number formatting
    setNumberLocale(locale === 'pt' ? 'de' : locale);
    setDateTimeLocale(locale);

    setDocumentLanguage(locale);

    if (!window.navigator.webdriver) {
      if (window.navigator.userAgent.indexOf('wisebot') < 0) {
        // Load UTAG lib only if not running in selenium/serenity webdriver
        loadUtagLib();
      }
    }

    const query = parseUrl();
    if (query.redirectToOrigin === 'true') {
      cleanAndReset();
    }

    log('Also load application...');
    const [languageMessages, app] = await Promise.all([
      languageLoader(),
      import('./App'),
    ]);
    setConfigurationValue('current.messages', languageMessages.default);

    log('Load tenant palette');
    const palette = getConfiguration('palette');

    log('Done');
    // eslint-disable-next-line react/no-did-mount-set-state
    this.setState({
      App: app.default,
      locale,
      messages: languageMessages.default,
      palette,
      theme,
    });

    body.classList.add(theme);
  }

  render() {
    if (this.state && this.state.App) {
      const AppComponent = this.state.App;
      return (
        <>
          <AppComponent
            locale={this.state.locale}
            messages={this.state.messages}
            palette={this.state.palette}
            theme={this.state.theme}
          />
        </>
      );
    }

    return null;
  }
}

export default Boot;
