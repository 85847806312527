import get from 'lodash/get';
import set from 'lodash/set';

let configurationStore = {};

/**
 *
 * @param {string} item Item selector
 * @param {any | undefined} defaultValue Default value
 * @returns
 */
export function getConfiguration(item, defaultValue = undefined) {
  return get(configurationStore, item, defaultValue);
}

export function setConfiguration(configuration) {
  configurationStore = configuration;
}

export function setConfigurationValue(key, value) {
  set(configurationStore, key, value);
}
