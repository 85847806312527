import { useEffect, useState, useRef } from 'react';

import {
  setSessionItem,
  getSessionItem,
  removeSessionItem,
  watchSessionItem,
} from './session';

import { utagView } from './utag';

/**
 * Hook to use session store
 *
 * @param {string} key Key to store data in session storage
 * @param {any} [defaultValue] Default value if no value in session storage
 * @returns {{value: any, setValue: function, remove: function, updateFromStore: function}} Return value
 */
export function useSessionStore(key, defaultValue) {
  const [value, internalSetValue] = useState(getSessionItem(key, defaultValue));

  useEffect(() => {
    const watchCallback = (newValue) => {
      internalSetValue(newValue);
    };

    const delWatch = watchSessionItem(key, watchCallback);

    return () => delWatch();
  }, [key]);

  const setValue = (newValue) => {
    internalSetValue(newValue);
    setSessionItem(key, newValue);
  };
  const remove = () => {
    removeSessionItem(key);
    internalSetValue(undefined);
  };
  const updateFromStore = () => {
    internalSetValue(getSessionItem(key));
  };

  return {
    value,
    setValue,
    remove,
    updateFromStore,
  };
}

/**
 * Hook to use U-Tag view tracking
 *
 * @param {string} page Page name to use for tracking
 * @param {string} disableCurrentSource TODO
 * @return {{undefined}} Return value
 */
export const useUTagView = (page = '', disableCurrentSource) => {
  useEffect(() => {
    if (!page) {
      return;
    }
    utagView(page, disableCurrentSource);
  }, []);
};

/**
 * useEffect like hook that executes only once
 *
 * @param {function} callback Callback function when value changed and has a value that is not undefined nor null
 * @param {any} value Value that is watched for changes
 * @returns {undefined}
 */
export function useEffectOnce(callback, value) {
  const executed = useRef(false);

  return useEffect(() => {
    if (executed.current === false && value !== null && value !== undefined) {
      executed.current = true;

      callback(value);
    }
  }, [value]);
}
