/* eslint-env browser */
import { getInterframe } from '@wise/wise-integration/lib/wise-iframe';

const channel = getInterframe();

export function postMessage(type, data) {
  return channel.send(type, data);
}

export function addListener(type, callback) {
  channel.addListener(type, (message) => callback(message.data, message));
}

export function inIframe() {
  try {
    return window.self !== window.top;
  } catch (error) {
    return true;
  }
}

export function fetchValue(type, timeout = 500) {
  return new Promise((resolve) => {
    let handler;

    const cleanUp = () => channel.removeListener(type, handler);

    const timeoutId = setTimeout(() => {
      cleanUp();
      resolve();
    }, timeout);

    handler = (message) => {
      resolve(message.data);

      clearTimeout(timeoutId);
      cleanUp();
    };

    channel.addListener(type, handler);
  });
}
