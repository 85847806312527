/* eslint-env browser */
import { parse } from 'cookie';

function getBaseDomain() {
  const domain = window.location.hostname.split(/\./g);

  return domain.slice(-2).join('.');
}

export function setOptOutCookie(newState) {
  if (newState !== '0' && newState !== '1') {
    throw new Error("newState of opt out cookie must be '0' or '1'");
  }

  const cookieValue = `WISE_OPT_OUT=${newState}; path=/; domain=${getBaseDomain()}; expires=Fri, 31 Dec 9999 23:59:59 GMT`;
  document.cookie = cookieValue;
}

function doNotTrack() {
  const navDoNotTrack = String(navigator.doNotTrack);

  if (navDoNotTrack === '1' || navDoNotTrack === 'yes') {
    return true;
  }

  const windowDoNotTrack = String(window.doNotTrack);

  if (windowDoNotTrack === '1' || windowDoNotTrack === 'yes') {
    return true;
  }

  return false;
}

export function getOptOutState() {
  // Disable utag tracking (always for now)
  return true;

  /*
  const currentCookieState = window.document.cookie;
  const cookies = parse(currentCookieState);

  if (cookies.WISE_OPT_OUT === undefined && doNotTrack()) {
    return true;
  }

  return cookies.WISE_OPT_OUT === '1';
  */
}

export default undefined;
