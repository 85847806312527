import { Route } from '../routes.types';

export const englishPageTitles: Record<Route, string> = {
  welcome: 'Welcome',
  'dwsjapan.wise': 'DWS Japan Wise',
  'dwsjapan.wise/faq': 'DWS Japan Wise FAQ',
  'how-it-works': 'How It Works',
  faq: 'FAQ',
  'investment-goal': 'Investment Goal',
  'personal-profile': 'Personal Profile',
  'investment-amount': 'Investment Amount',
  'investment-horizon': 'Investment Horizon',
  risk: 'Risk',
  'investment-plan': 'Investment Plan',
  knowledge: 'Knowledge',
  'financial-services': 'Financial Services',
  experience: 'Experience',
  'nearly-there': 'Nearly There',
  'financial-status': 'Financial Status',
  'financial-status-income': 'Financial Status Income',
  'financial-status-disposable': 'Financial Status Disposable',
  'financial-status-assets': 'Financial Status Assets',
  'financial-status-liabilities': 'Financial Status Liabilities',
  sustainability: 'Sustainability',
  'sustainability-landing': 'Sustainability Landing',
  'sustainability-pick': 'Sustainability Pick',
  'sustainability-criteria': 'Sustainability Criteria',
  summary: 'Summary',
  handover: 'Handover',
};
