import PropTypes from 'prop-types';
import { getConfiguration } from './configuration';

export function isShortJourney() {
  return Boolean(getConfiguration('isShortJourney'));
}

export function isSkipReviewPage() {
  return Boolean(getConfiguration('fields.skipReviewPage'));
}

export function isSkipInvestmentHorizonPage() {
  return Boolean(getConfiguration('fields.skipInvestmentHorizonPage'));
}

export function isSkipNearlyTherePage() {
  return Boolean(getConfiguration('fields.skipNearlyTherePage'));
}

export function isFinancialStatusAccordion() {
  return Boolean(getConfiguration('fields.financialStatusAccordion'));
}

function Empty() {
  return null;
}

export function allowsKnowledgeNextButton() {
  return Boolean(getConfiguration('fields.allowKnowledgeNextButton'));
}

/**
 * Render component based on configuration value of tenant
 *
 * @param {Object} obj - Configuration object
 * @param {any} obj.config - Configuration key to decide if component or fallback is rendered
 * @param {any} obj.component - Component to render if config value is trueish
 * @param {any} obj.fallback - Component to render if config value is falsy
 * @return {Object} obj - Component
 */
export function IfConfiguration({ config, component, fallback }) {
  const shouldHide = Array.isArray(config)
    ? config.some((item) => getConfiguration(item))
    : getConfiguration(config);

  return shouldHide ? component : fallback;
}

IfConfiguration.propTypes = {
  config: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  component: PropTypes.node,
  fallback: PropTypes.node,
};

IfConfiguration.defaultProps = {
  component: Empty,
  fallback: Empty,
};

export function IfShortJourney({ component, fallback }) {
  return IfConfiguration({
    config: 'isShortJourney',
    component,
    fallback,
  });
}

IfShortJourney.propTypes = {
  component: PropTypes.node,
  fallback: PropTypes.node,
};

IfShortJourney.defaultProps = {
  component: Empty,
  fallback: Empty,
};

export default null;
