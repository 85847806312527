import i18next from 'i18next';
import { Language } from '../app/i18n';
import { germanPageTitles } from '../app/tenants/de.manual';
import { Route } from '../app/routes.types';
import { englishPageTitles } from '../app/tenants/en.manual';
import { portuguesePageTitles } from '../app/tenants/pt.manual';

export const getTitleByRoute = (route?: Route): string | void => {
  if (!route) return;
  const language: Language = i18next.language as Language;
  let title;

  switch (language) {
    case Language.DE:
      title = germanPageTitles[route];
      break;
    case Language.EN:
      title = englishPageTitles[route];
      break;
    case Language.PT:
      title = portuguesePageTitles[route];
      break;
    default:
      console.error('Invalid language');
  }
  return title;
};
