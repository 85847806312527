import { sessionFactory } from '@wise/wise-session';

let session = null;
export function init(clientId) {
  if (!clientId) {
    throw new Error('No clientid given to session storage initialization');
  }

  session = sessionFactory(clientId);
}

const watchers = new Map();
/**
 * Watches session item changes - done with this module!!
 *
 * @param {string} key Key of item in key/value session storage
 * @param {function} callback Callback when item changed
 * @returns {function} Delete watch
 */
export function watchSessionItem(key, callback) {
  if (!watchers.has(key)) {
    watchers.set(key, new Set());
  }

  watchers.get(key).add(callback);

  return () => {
    const callbacks = watchers.get(key);
    callbacks.delete(callback);
    if (callbacks.size === 0) {
      watchers.delete(key);
    }
  };
}

/**
 * Set item in session storage
 *
 * @param {String} key Key of item in key/value session storage
 * @param {*} value Value of item to store in session
 * @returns {Boolean} If item is set in session storage
 */
export function setSessionItem(key, value) {
  if (!session) {
    throw new Error('Session not initialized');
  }

  const returnValue = session.setItem(key, value);

  if (watchers.has(key)) {
    for (const callback of watchers.get(key)) {
      callback(value, key);
    }
  }

  return returnValue;
}

/**
 * Get item from session storage
 *
 * @param {String} key Key of item in key/value session storage
 * @param {*} [defaultValue] Default value if key is not available in session key/value storage
 * @returns {*} Value that is stored in key/value session storge
 */
export function getSessionItem(key, defaultValue) {
  if (!session) {
    throw new Error('Session not initialized');
  }

  return session.getItem(key, defaultValue);
}

/**
 * Remove item from key/value session storage
 *
 * @param {String} key Key of item in key/value session storage
 * @returns {void} Nothing
 */
export function removeSessionItem(key) {
  if (!session) {
    throw new Error('Session not initialized');
  }

  return session.removeItem(key);
}

/**
 * Clear key/value session storage.
 *
 * @returns {void} Nothing
 */
export function clearSession() {
  if (!session) {
    throw new Error('Session not initialized');
  }

  return session.clear();
}
