import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import {
  setLanguage as storeLanguage,
  mapLanguageToLocale,
} from './services/language';
import de from './tenants/de';

export const LOCALES_WITH_PREFIX_CURRENCY = ['en', 'yue', 'ja'];

export enum Language {
  EN = 'en',
  DE = 'de',
  PT = 'pt',
}

export const setLanguage = (languageValue: Language) => {
  storeLanguage(languageValue);
  return Promise.resolve(languageValue);
};

export const currencyIsPrefix = () => {
  const language = i18next.language;
  return LOCALES_WITH_PREFIX_CURRENCY.indexOf(language) > -1;
};

export const localizedValue = (localeObject: any) => {
  const unspecificLanguage = i18next.language;
  const specificLanguage = mapLanguageToLocale(i18next.language);

  return (
    localeObject.localizedText[specificLanguage] ||
    localeObject.localizedText[unspecificLanguage]
  );
};

export const localeMap = () => {
  const currentLanguage = i18next.language;

  if (currentLanguage.indexOf(Language.PT) === 0) {
    return {
      numberLocale: Language.DE,
      dateTimeLocale: Language.PT,
    };
  }

  const currentLocale = mapLanguageToLocale(currentLanguage);
  return {
    numberLocale: currentLocale,
    dateTimeLocale: currentLocale,
  };
};

i18next.use(initReactI18next).init({
  debug: false,
  fallbackLng: Language.DE,
  interpolation: {
    escapeValue: false,
    prefix: '{',
    suffix: '}',
    format: (value, format, lng) => {
      if (format === 'intlDate') {
        return new Intl.DateTimeFormat(lng).format(value);
      }

      if (format === 'intlNumber') {
        return new Intl.NumberFormat(lng).format(value);
      }

      return value;
    },
  },
  resources: {
    de: {
      translation: {
        ...de,
      },
    },
  },
});

export default i18next;
