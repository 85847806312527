/* globals DocumentTouch */
import MobileDetect from 'mobile-detect';

const matchMedia = window.matchMedia || (() => false);

export const isTouchDevice =
  'ontouchstart' in window || // Most modern browsers.
  navigator.maxTouchPoints || // Works on IE10/11 and Surface.
  (window.DocumentTouch && document instanceof DocumentTouch) || // Dunno, from modernizr.
  matchMedia('(-moz-touch-enabled: 1)').matches; // Firefix specific but needed with ontouchstart?

export function isMobileOrTablet() {
  return window.innerWidth <= 1024 && window.innerHeight >= 280;
}

/* eslint-disable no-console */
export function detectDeviceType(ua) {
  if (!isTouchDevice) {
    return 'desktop';
  }

  // If it's a touch device over 1025px we use a whitelist and
  // otherwise we prompt the user as we can't know for sure.
  if (matchMedia('(min-device-width: 1025px)')) {
    const md = new MobileDetect(ua);
    const match = md.mobile() && md.mobileGrade() === 'A';

    if (!match) {
      console.info('Device was >= 1025px but no match was found');
      // The user should tell us what device this is.
      return null;
    }
  }

  return 'mobile';
}
