import { Route } from '../routes.types';

export const portuguesePageTitles: Record<Route, string> = {
  welcome: 'Bem-vindo',
  'dwsjapan.wise': 'DWS Japão Wise',
  'dwsjapan.wise/faq': 'DWS Japão Wise FAQ',
  'how-it-works': 'Como Funciona',
  faq: 'FAQ',
  'investment-goal': 'Objetivo de Investimento',
  'personal-profile': 'Perfil Pessoal',
  'investment-amount': 'Valor do Investimento',
  'investment-horizon': 'Horizonte de Investimento',
  risk: 'Risco',
  'investment-plan': 'Plano de Investimento',
  knowledge: 'Conhecimento',
  'financial-services': 'Serviços Financeiros',
  experience: 'Experiência',
  'nearly-there': 'Quase Lá',
  'financial-status': 'Status Financeiro',
  'financial-status-income': 'Status Financeiro Renda',
  'financial-status-disposable': 'Status Financeiro Disponível',
  'financial-status-assets': 'Status Financeiro Ativos',
  'financial-status-liabilities': 'Status Financeiro Passivos',
  sustainability: 'Sustentabilidade',
  'sustainability-landing': 'Sustentabilidade Landing',
  'sustainability-pick': 'Escolha de Sustentabilidade',
  'sustainability-criteria': 'Critérios de Sustentabilidade',
  summary: 'Resumo',
  handover: 'Entrega',
};
