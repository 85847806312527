function checkConsoleCapabilities() {
  const hasConsole = typeof console !== 'undefined';
  const hasConsoleLog = hasConsole && typeof window.console.log === 'function';

  return [hasConsole, hasConsoleLog];
}

const hasDebug = window.location.search
  .substring(1)
  .split('&')
  .includes('debug');
const [hasConsole, hasConsoleLog] = checkConsoleCapabilities();

function processMessages(messages) {
  return messages.map((message) => {
    if (message.stack) {
      return `${message.message}<br><pre>${message.stack}</pre>`;
    }
    return message;
  });
}

const messageQueue = [];
let logInDOM = null;

function logOutput(type, messages) {
  if (logInDOM) {
    const message = window.document.createElement('div');
    message.style.margin = '5px';
    message.style.backgroundColor = '#eeeeee';
    message.innerHTML = `${type}: ${processMessages(...messages).join(' ')}`;
    logInDOM.appendChild(message);
  } else if (hasConsole && window.console[type]) {
    window.console[type].call(window.console, ...messages);
  } else if (hasConsole && hasConsoleLog) {
    window.console.log.apply(window.console, [type, ...messages]);
  }
}

function doLog(type, messages) {
  if (hasConsole && !hasDebug) {
    logOutput(type, messages);
  } else {
    messageQueue.push([type, messages]);
  }
}

function displayLogInDOM() {
  logInDOM = window.document.createElement('div');
  window.document.body.appendChild(logInDOM);

  const styling = {
    position: 'absolute',
    width: '500px',
    top: '10px',
    right: '10px',
    bottom: '10px',
    border: '3px solid black',
    backgroundColor: 'white',
    zIndex: '99999',
    'overflow-x': 'hidden',
    'overflow-y': 'scroll',
  };
  for (const style of Object.keys(styling)) {
    logInDOM.style[style] = styling[style];
  }

  while (messageQueue.length > 0) {
    const [itemType, ...itemMessage] = messageQueue.shift();
    logOutput(itemType, itemMessage);
  }
}

function keyDownEventHandler(event) {
  const isSpecialKey =
    event.code === 'KeyI' && event.altKey && event.shiftKey && event.metaKey;

  if (isSpecialKey) {
    window.document.removeEventListener('keydown', keyDownEventHandler);
    displayLogInDOM();
  }
}
window.document.addEventListener('keydown', keyDownEventHandler);
window.wiseDebugger = () => displayLogInDOM();

export const log = (...messages) => doLog('log', messages);
export const debug = (...messages) => doLog('debug', messages);
export const error = (...messages) => doLog('error', messages);
export const warn = (...messages) => doLog('warn', messages);
export const info = (...messages) => doLog('info', messages);
export const table = (tableValues) => doLog('table', [tableValues]);
