import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import {
  isShortJourney,
  isSkipReviewPage,
  isSkipInvestmentHorizonPage,
  isSkipNearlyTherePage,
  isFinancialStatusAccordion,
} from './isTenant';
import { getConfiguration } from './configuration';

const summaryPrevious = () => {
  if (getConfiguration('ESGRoute').enabled) {
    return '/sustainability-criteria';
  } else {
    return isFinancialStatusAccordion()
      ? 'financial-status'
      : '/financial-status-liabilities';
  }
};

let pageFlow = [];

export const setNavigationFlow = (navigationFlow) => {
  pageFlow = [
    {
      name: '/welcome',
      next: '/investment-goal',
    },
    {
      name: '/embed',
      next: '/investment-goal',
    },
    {
      name: '/investment-goal',
      previous: () => (getConfiguration('embedded') ? '/embed' : '/welcome'),
    },
    {
      name: '/personal-profile',
      previous: '/investment-goal',
    },
    {
      name: '/investment-amount',
    },
    {
      name: '/investment-horizon',
    },
    {
      name: '/risk',
      previous: () =>
        isSkipInvestmentHorizonPage()
          ? '/investment-amount'
          : '/investment-horizon',
      next: () => (isSkipReviewPage() ? '/knowledge' : '/investment-plan'),
    },
    {
      name: '/investment-plan',
      next: () => {
        const shortJourney = isShortJourney();
        const financialStatusAccordion = isFinancialStatusAccordion();

        if (shortJourney) {
          return financialStatusAccordion
            ? '/financial-status'
            : '/financial-status-assets';
        }
        return '/knowledge';
      },
    },
    {
      name: '/knowledge',
      previous: () =>
        isShortJourney() || isSkipReviewPage() ? '/risk' : '/investment-plan',
    },
    {
      name: '/financial-services',
    },
    {
      name: '/experience',
    },
    {
      name: '/nearly-there',
      previous: () => (isShortJourney() ? '/investment-plan' : '/experience'),
      next: () =>
        isFinancialStatusAccordion()
          ? 'financial-status'
          : '/financial-status-income',
    },
    {
      name: '/financial-status-income',
      previous: () => {
        if (isShortJourney()) return '/investment-plan';
        if (isSkipNearlyTherePage()) return '/experience';
        return '/nearly-there';
      },
    },
    {
      name: '/financial-status-disposable',
    },
    {
      name: '/financial-status-assets',
    },
    {
      name: '/financial-status-liabilities',
    },
    {
      name: '/summary',
      previous: summaryPrevious,
    },
    {
      name: '/handover',
    },
  ];
  const sustainabilityFlow = [
    {
      name: '/sustainability',
      previous: isFinancialStatusAccordion()
        ? 'financial-status'
        : '/financial-status-liabilities',
    },
    {
      name: '/sustainability-pick',
    },
    {
      name: '/sustainability-criteria',
    },
  ];

  if (navigationFlow) {
    pageFlow = navigationFlow;
    return;
  }

  if (getConfiguration('ESGRoute')?.enabled) {
    pageFlow.splice(16, 0, ...sustainabilityFlow);
  }
};

export function getNextPage(
  currentHash = window.location.hash.replace('#', '')
) {
  const currentPage = find(pageFlow, { name: currentHash });
  if (currentPage.next) {
    return typeof currentPage.next === 'function'
      ? currentPage.next()
      : currentPage.next;
  }
  const nextPageIndex = findIndex(pageFlow, { name: currentPage.name }) + 1;
  return pageFlow[nextPageIndex].name;
}

export function getPreviousPage(
  currentHash = window.location.hash.replace('#', '')
) {
  const currentPage = find(pageFlow, { name: currentHash });
  if (currentPage.previous) {
    const previousPage = currentPage.previous;
    return typeof previousPage === 'function'
      ? currentPage.previous()
      : currentPage.previous;
  }
  const previousPageIndex = findIndex(pageFlow, { name: currentPage.name }) - 1;
  return pageFlow[previousPageIndex].name;
}
