import { clearSession } from './session';

export function cleanAndReset() {
  try {
    clearSession();
  } catch (error) {
    // noop
  }
  try {
    localStorage.clear();
  } catch (error) {
    // noop
  }
  document.location.search = '';
}
