import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { ErrorBox } from '@wise/ui-error-box';

import { useUTagView } from '../services/hooks';

import './NoMatch.css';

const NoMatchComponent = () => {
  useUTagView('nomatch');
  const { t } = useTranslation();
  const title = t('errorpage-title');
  const supportCopy = {
    'support-intro': t('support-intro-header'),
    'support-tel-intro': t('support-tel-intro'),
    'support-email-intro': t('support-email-intro'),
    'support-tel': t('support-tel'),
    'support-email': t('support-email'),
    'support-hours-1': t('support-hours-1'),
    'support-hours-2': t('support-hours-2'),
  };

  return (
    <div>
      <Helmet title="Page Not Found" />
      <ErrorBox title={title} supportCopy={supportCopy} />
    </div>
  );
};

export default NoMatchComponent;
