import { parseUrl } from '@wise/shared/src/string-helper';
import { isShortJourney } from './isTenant';
import { set, get } from './localStorage';

export const LOCALES_WITH_PREFIX_CURRENCY = ['en', 'yue', 'ja'];
const LANG_KEY = 'site.language';

export function setLanguage(language) {
  try {
    set(LANG_KEY, language);
  } catch (error) {
    // empty
  }
}

function detectLanguage(defaultLanguage, supportedLanguages) {
  return new Promise((resolve, reject) => {
    const parsedUrl = parseUrl() || {};
    const storedLanguage = parsedUrl.lang || get(LANG_KEY);

    if (supportedLanguages.indexOf(storedLanguage) < 0) {
      return reject(new Error('language not supported'));
    }

    return resolve(storedLanguage);
  }).catch(() => {
    const navigatorLanguage = (
      (navigator.languages && navigator.languages[0]) ||
      navigator.language
    ).split('-')[0];

    let language = defaultLanguage;
    if (supportedLanguages.indexOf(navigatorLanguage) >= 0) {
      language = navigatorLanguage;
    }

    setLanguage(language);

    return language;
  });
}

// app language might be different than browser locale
export function mapLanguageToLocale(language) {
  switch (language) {
    case 'de':
      return 'de-DE';
    case 'en':
      return 'en-GB';
    case 'zh':
      return 'zh-CN';
    case 'yue':
      return 'zh-TW';
    case 'pt':
      return 'pt-PT';
    case 'ja':
      return 'ja-JP';
    default:
      return 'en-GB';
  }
}

export function proxyLanguage(locale) {
  if (!isShortJourney()) {
    return ['zh', 'yue'].indexOf(locale) > -1 ? 'en' : locale;
  }
  if (isShortJourney()) {
    return ['yue'].indexOf(locale) > -1 ? 'zh-TW' : locale;
  }
  return locale;
}

export default detectLanguage;
